import {
  Col, Form, Input, Button, message,
} from 'antd';
import React from 'react';
// import Config from '../../../../config';


const encode = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

const validateMessages = {
  required: 'This field is required!',
  types: {
    email: 'Not a valid email!',
  },
};

export default () => {
  const formName = 'contact';
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    // if (values['bot-field'] === undefined) {
    //   delete values['bot-field'];
    // }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        ...values,
      }),
    })
      .then(() => {
        message.success('Thank you! I will get back to you shortly.');
        form.resetFields();
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Error:', error));
  };

  // const onFinish = (data) => {
  //   console.log("data", data)
  //   const formData = new FormData();
  //   eslint-disable-next-line no-restricted-syntax
  //   for (const key in data) {
  //     if (data[key]) {
  //       formData.append(key, data[key]);
  //     }
  //   }

  //   console.log('FORM DATA:', formData)

  //   fetch('/', { method: 'POST', body: formData })
  //     .then(() => {
  //       message.success('Thank you! I will get back to you shortly.');
  //       form.resetFields();
  //     })
  //     // eslint-disable-next-line no-console
  //     .catch((error) => console.error('Error:', error));
  // };

  return (
    <Col sm={24} md={24} lg={12} className="widthFull">
      <form
        name={formName}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        hidden
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="text" name="name" />
        <input type="email" name="email" />
        <textarea name="message" />
      </form>
      <Form form={form} name="nest-messages" onFinish={handleSubmit} validateMessages={validateMessages}>
        <Form.Item name={['name']} rules={[{ required: true }]}>
          <Input size="large" placeholder="Full Name *" />
        </Form.Item>
        <Form.Item name={['email']} rules={[{ type: 'email' }]}>
          <Input size="large" placeholder="Email" />
        </Form.Item>
        <Form.Item name={['message']} rules={[{ required: true }]}>
          <Input.TextArea size="large" rows={7} placeholder="Message *" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" shape="round" size="large" htmlType="submit" style={{ background: '#304CFD' }}>
            SUBMIT
          </Button>
        </Form.Item>
      </Form>
    </Col>
  );
};
